@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --purple-dark: #1f193f;
  --black-dark: #010101;
  --sky-blue: #00b0ef;
  --dark-sky-blue: #009ce1;
  --yellow: #efa300;
  --brown-dark: #9a575b;
  --blue: #d9f3ff;
  --blue-dark: #0972a0;
  --transition-3s: all 0.3s ease-in-out;
}
html,
body {
  overflow-x: hidden;
}
.inner_plan_section,
.inner_plan_section ul,
.inner_plan_section h1,
.inner_plan_section h2,
.inner_plan_section h3,
.inner_plan_section h4,
.inner_plan_section h5,
.inner_plan_section h6,
.inner_plan_section p,
body.outer_app,
.outer_app ul,
.outer_app h1,
.outer_app h2,
.outer_app h3,
.outer_app h4,
.outer_app h5,
.outer_app h6,
.outer_app p {
  font-family: "Poppins", sans-serif;
}
.outer_app img {
  max-width: 100%;
}
.b_header {
  padding-block: 30px;
  border-bottom: 2px solid #c8c8c8;
  transition: var(--transition-3s);
  z-index: 9;
}
.b_header.b_scrolled {
  background-color: #f3f3f3;
}
.b_btn {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  padding-inline: 1rem;
  font-size: 20px;
  justify-content: center;
}
.b_menu_list a:not(.b_btn) {
  font-size: 16px;
  color: var(--purple-dark);
  font-weight: 500;
  transition: var(--transition-3s);
}
.outer_app .text-paragraph {
  color: var(--purple-dark);
}
.b_menu_list a:not(.b_btn):hover,
.b_menu_list a:not(.b_btn).active {
  color: var(--sky-blue);
}
.b_menu_list a.b_btn {
  font-size: 18px;
  border-radius: 25px;
  min-height: 50px;
  min-width: 125px;
  font-weight: 600;
}
.b_btn_white {
  background: #fff;
  color: var(--purple-dark);
  padding-left: 10px;
}
.b_btn_white:hover {
  background: #fff;
}
.b_btn_blue:hover,
.b_btn_blue {
  background-color: var(--sky-blue);
  color: #fff;
}
.b_hero_section {
  background-image: url("../../public/images/new/bannershape.svg"),
    url("../../public/images/new/banner-img.png"),
    url("../../public/images/new/home-banner.png");
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: bottom -5px center, right 37% bottom, center bottom -5px;
  padding-block: 210px 310px;
  background-size: 100%, auto, cover;
  background-color: #f3f3ff;
}
b,
strong {
  font-weight: 700;
}
.b_hero_content {
  max-width: 820px;
}
.b_hero_content h1 {
  font-size: 58px;
  font-weight: 400;
  line-height: 66px;
  color: var(--purple-dark);
}
.b_hero_content p {
  color: var(--purple-dark);
  font-size: 18px;
}
.b_hero_content h1 span {
  color: var(--black-dark);
  font-weight: bold;
}
.text_blue {
  color: var(--dark-sky-blue) !important;
}
.b_hero_content > *:not(:last-child) {
  margin-bottom: 25px;
}
.b_btn-yellow:hover,
.b_btn-yellow {
  background-color: var(--yellow);
  color: #fff;
  border-radius: 25px;
  height: 50px;
  padding-inline: 25px;
}
.b_btn-yellow span {
  height: 33px;
  width: 33px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  overflow: hidden;
}
.b_btn-yellow span img {
  position: relative;
}
.b_btn-yellow:hover span img {
  animation: btn_arw_animation 0.5s 1;
}

@keyframes btn_arw_animation {
  0% {
    right: 0;
    top: 0;
    opacity: 1;
  }
  33% {
    right: -20px;
    top: -20px;
    opacity: 0;
  }
  66% {
    right: 20px;
    opacity: 0;
    top: 20px;
  }
  100% {
    opacity: 1;
    right: 0;
    top: 0;
  }
}
.b_brain_img_sec {
  background-color: #f3f3ff;
}
.b_brain_img_sec .text-paragraph {
  font-size: 18px;
}
.b_brain_img_sec .two-block-image-area {
  padding-right: 0;
}
.benefits_item {
  padding: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
}
.benefits_item:nth-child(2) {
  background-image: linear-gradient(to right, #f3f3ff, #fff);
  border-radius: 23px;
}
.benefits_item h3 {
  color: var(--yellow);
  font-size: 30px;
  font-weight: 600;
}
.benefits_item p {
  font-size: 18px;
  color: var(--purple-dark);
  line-height: 30px;
  text-align: justify;
}
.b_over_img {
  border: 6px solid #fff;
  border-radius: 60px;
  max-width: max-content;
  margin-inline: auto;
  overflow: hidden;
  display: flex;
  margin-bottom: 50px;
}
.b_over_img img {
  max-width: 960px;
  width: 100%;
  aspect-ratio: 1.92/1;
  object-fit: cover;
}
.b_CONSIGUE_section {
  background-image: url("../../public/images/new/multiple-people.png");
  background-repeat: no-repeat;
  background-position: bottom center;
}

.b_CONSIGUE_section .text-paragraph {
  font-size: 20px;
  font-weight: 500;
}
.b_CONSIGUE_section .two-block-content-area {
  background-image: url("../../public/images/new/arw-2.png");
  background-repeat: no-repeat;
  background-position: left 34% bottom 4%;
}
.b_CONSIGUE_section .main-title {
  display: flex;
  justify-content: center;
  padding-left: 117px;
}
.b_CONSIGUE_section .main-title span {
  font-weight: normal;
}
.b_CONSIGUE_section .main-title span b {
  font-weight: 700;
}
.b_CONSIGUE_section .main-title::after {
  content: url("../../public/images/new/arw-1.png");
  margin-left: 25px;
}
.learnCard.brown {
  background-image: linear-gradient(to bottom, #fce8e8, #fff);
}
.learnCard.blue {
  background-image: linear-gradient(to bottom, #d9f3ff, #fff);
}
.arrow_title b {
  position: relative;
}
.arrow_title b::before {
  content: url("../../public/images/new/arw-3.png");
  position: absolute;
  left: -210px;
  top: 35px;
}
.arrow_title b::after {
  content: url("../../public/images/new/arw-4.png");
  position: absolute;
  right: -190px;
  top: 35px;
}
.quote_sec {
  padding-left: 100px;
  position: relative;
}
.quote_sec > span {
  border: 10px solid #fff;
  border-radius: 100%;
  background-color: var(--blue);
  display: inline-flex;
  height: 163px;
  width: 163px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translateY(-50%);
}
.blueGradient_sec .main-title {
  font-size: 42px;
  line-height: 52px;
  font-weight: 600;
  margin-bottom: 0;
}
.garantia_Sec .sub-heading {
  color: var(--yellow);
  font-weight: 600;
}
.garantia_Sec .text-paragraph {
  font-size: 18px;
  line-height: 30px;
}
.registraton_link:hover,
.registraton_link {
  border-radius: 57px;
  filter: drop-shadow(11.395px 33.093px 24.5px rgba(0, 0, 0, 0.19));
  background-color: #009ae0;
  border: 6px solid #ffffff;
  display: inline-block;
  font-size: 36px;
  line-height: 47px;
  color: #ffffff;
  padding: 30px 25px;
  font-weight: 600;
}
.garantia_Sec {
  padding-bottom: 180px;
}
.registration-section {
  position: absolute;
  top: -57px;
  width: 100%;
  max-width: calc(100% - 24px);
  left: 50%;
  transform: translateX(-50%);
}
.footer_copyright {
  padding: 20px 0;
  text-align: center;
  color: var(--purple-dark);
  font-size: 18px;
  font-weight: 500;
}
.footer_copyright p {
  font-size: 16px;
}
.main_footer * {
  color: var(--purple-dark) !important;
  font-weight: 500;
  font-size: 18px;
}
.main_footer a:hover,
.main_footer *.active {
  color: var(--dark-sky-blue) !important;
}
.footer_title {
  font-size: 30px;
  line-height: 40px;
  color: #010101;
  font-weight: 700;
  margin-bottom: 27px;
}
.titleSection {
  background-image: url("../../public/images/new/title-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0;
  text-align: center;
}

.titleSection h1 {
  font-size: 48px;
  color: #fff;
  font-weight: 600;
}
.text_image_list h3 {
  font-size: 30px;
  color: var(--sky-blue);
  font-weight: 600;
  margin-bottom: 15px;
}
.text_image_list p {
  font-size: 18px;
  line-height: 30px;
  color: #6b6a6c;
  text-align: justify;
}
.text_image_item:not(:last-child) {
  margin-bottom: 50px;
}
.plan_section.number_background,
.number_background {
  background-image: url("../../public/images/new/ColorFill.png");
  background-repeat: no-repeat;
  background-position: right top;
}
.functional_section .main-title {
  display: inline-block;
  position: relative;
}
.functional_section .main-title::after {
  content: url("../../public/images/new/arw-4.png");
  position: absolute;
  right: -150px;
  top: 30px;
}
.function_row {
  margin-top: 120px;
}
.function_item {
  border-radius: 50px;
  background-image: linear-gradient(to bottom, rgb(244, 244, 255), #fff);
  padding-inline: 10px;
  padding-bottom: 25px;
}
.function_item .function_icon {
  border-radius: 56px;
  filter: drop-shadow(3.812px 5.871px 4.5px rgba(0, 0, 0, 0.04));
  background-color: rgb(244, 244, 255);
  border: 5px solid #ffffff;
  display: inline-flex;
  width: 111px;
  height: 111px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -55px;
  margin-bottom: 20px;
}
.function_item h4 {
  font-size: 30px;
  color: var(--sky-blue);
  font-weight: 600;
  margin-bottom: 15px;
}
.function_item p {
  font-size: 18px;
  line-height: 30px;
  color: #6b6a6c;
  font-weight: 400;
}
.b_border_btn:hover,
.b_border_btn {
  height: 50px;
  border-radius: 25px;
  border: 1px solid #010101;
  color: #000;
  padding-inline: 25px;
  font-size: 18px;
  font-weight: 600;
}
.b_border_btn span {
  font-size: 24px;
  line-height: 16px;
  position: relative;
  top: 1px;
}
.benefit_text h4 {
  font-size: 24px;
  color: #08080a;
  margin-bottom: 10px;
  font-weight: 600;
}
.benefit_text h4 + p {
  font-size: 18px;
  line-height: 30px;
  color: #6b6a6c;
}
.paso_title {
  font-size: 30px;
  color: var(--sky-blue);
  font-weight: 600;
  margin-bottom: 30px;
}
.hiw_title {
  position: relative;
  display: inline-block;
}
.hiw_title::after {
  content: url("../../public/images/new/arw-4.png");
  position: absolute;
  right: -170px;
  top: 100px;
}
.paso_section {
  background-color: #f7f7f7;
}
.paso_section .benefits_item {
  margin-bottom: 20px;
  height: calc(100% - 20px);
  background-image: linear-gradient(to right, #e7f1f6, #f7f7f7);
  border-radius: 23px;
}
.hiw_title.price_title h2 {
  text-shadow: 0 0 21px #fff;
}
.hiw_title.price_title p {
  font-size: 18px;
  font-weight: 600;
  text-shadow: 0 0 21px #fff;
}
.price_title.hiw_title::after {
  right: 60px;
  top: 30px;
}
.back-link-header.inner-header.loginHeader {
  padding-block: 30px;
}
.hiw_title.faq_title::after {
  right: -80px;
  top: 30px;
}
.hiw_title.contact_title::after {
  right: -70px;
  top: 12px;
}
.hiw_title.contact_title p {
  font-size: 18px;
  color: var(--purple-dark);
  font-weight: 600;
  margin-top: 30px;
}
.plan_section {
  background-image: url("../../public/images/new/new/Comprar2.png");
  background-repeat: no-repeat;
  background-position: right calc((100vw - 1520px - 180px) / 2) top 30px;
}

.plan_section ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style: none;
  grid-gap: 15px;
}

.plan_section ul li {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 12px;
  font-size: 18px;
  color: var(--purple-dark);
  font-weight: 600;
}
.plan_section ul li::before {
  content: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.9 8.1L4.5 9.5L9 14L19 4L17.6 2.6L9 11.2L5.9 8.1ZM18 10C18 14.4 14.4 18 10 18C5.6 18 2 14.4 2 10C2 5.6 5.6 2 10 2C10.8 2 11.5 2.1 12.2 2.3L13.8 0.7C12.6 0.3 11.3 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10H18Z" fill="%23009CE1"/></svg>');
  width: 20px;
  height: 20px;
  object-fit: contain;
  position: relative;
  top: 3px;
}

.price_card {
  display: grid;
  grid-template-columns: 140px 1fr;
  padding-right: 70px;
}
.price_bookmark_tag {
  background-color: var(--blue);
  border-radius: 100%;
  border: 5px solid var(--bs-white);
  width: 95px;
  height: 95px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: -90px;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.price_card_bg {
  border-radius: 20px;
  filter: drop-shadow(11.395px 33.093px 24.5px rgba(0, 0, 0, 0.19));
  background-image: linear-gradient(-26deg, #009be1 0%, #253d7f 100%);
  border: 6px solid var(--bs-white);
  padding: 40px 30px 30px 100px;
  display: grid;
  grid-template-columns: 320px auto 1fr;
  align-items: center;
  gap: 50px;
}

.access_tag {
  border-radius: 35px;
  background-image: linear-gradient(
    to right,
    var(--dark-sky-blue),
    transparent
  );
  font-size: 18px;
  color: var(--bs-white);
  font-weight: 500;
  padding: 10px 60px 10px 18px;
  width: max-content;
}
.price_discount h5 {
  font-size: 19px;
  text-transform: uppercase;
  color: #f3d112;
  font-weight: 600;
}

.price_discount h3 {
  font-size: 30px;
  text-transform: uppercase;
  color: var(--bs-white);
  font-weight: 500;
}
.price_discount h3 span {
  position: relative;
}

.price_discount h3 span::before {
  content: "";
  height: 3px;
  width: 100%;
  background-color: var(--bs-white);
  position: absolute;
  left: -3px;
  top: 19px;
  transform: rotate(339deg);
}

.price_action button,
.price_action a {
  border-radius: 31px;
  background-color: var(--bs-white);
  border: 0;
  min-height: 60px;
  padding: 0 30px;
  font-size: 22px;
  line-height: 62px;
  color: #009ce1;
  color: var(--dark-sky-blue) !important;
  font-weight: 600;
}
.price_action p {
  color: var(--bs-white);
  font-size: 14px;
  color: var(--bs-white);
  font-weight: 400;
}
.main_faq_sec {
  background-image: linear-gradient(to bottom, #fbfbfb, #ffffff);
  padding-bottom: 180px;
}

.main_faq_sec .accordion-item:not(:last-child) {
  margin-bottom: 30px;
}
.main_faq_sec .accordion-item .accordion-button {
  background-color: transparent;
  font-size: 24px;
  color: #08080a;
  font-weight: 600;
  box-shadow: none;
  padding: 0;
  padding-right: 25px;
}
.main_faq_sec .accordion-item .accordion-button::after {
  background-image: url("../../public/images/new/plus.svg");
  position: absolute;
  right: 0;
  top: 3px;
  height: 20px;
  width: 20px;
  background-position: center;
}
.main_faq_sec .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: url("../../public/images/new/minus.svg");
}
.main_faq_sec .accordion-item .accordion-body {
  padding: 30px 0 0 0;
  font-size: 18px;
  line-height: 30px;
  color: #6b6a6c;
}
.main_faq_sec .accordion-item {
  border-radius: 20px;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  padding: 20px 30px;
}
.main_faq_sec .accordion-item:has([aria-expanded="true"]) {
  background-color: #ffffff;
  filter: drop-shadow(3.812px 5.871px 4.5px rgba(0, 0, 0, 0.06));
  border: 1px solid #009ce1;
}
.contact_form {
  max-width: 815px;
  margin-inline: auto;
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 30px;
  margin-top: 60px;
}
.contact_main_form {
  margin-top: 60px;
}
.contact_main_form h3 {
  font-size: 36px;
  color: var(--purple-dark);
  font-weight: 700;
}
.contact_info {
  background-color: #f5f5f5;
  border: 1px solid #cfcfcf;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}
.contact_info a {
  font-size: 14px;
  line-height: 24px;
  color: var(--purple-dark);
}
.contact_form_part .form-control {
  min-height: 60px;
  border-radius: 10px;
  background-color: #f9f9f9;
  resize: none;
  border: 1px solid #cfcfcf;
}
.contact_form_part .form-control:focus {
  filter: drop-shadow(0px 5px 8px rgba(0, 77, 118, 0.13));
  border: 1px solid #00b0ef;
  box-shadow: none;
}
.contact_form_part {
  position: relative;
}
.contact_main_form {
  margin-bottom: 150px;
}
.contact_form_part .main-button {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  min-width: 150px;
  font-size: 18px;
}
.contact_form_part label {
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 24px;
  color: var(--purple-dark);
  font-weight: 600;
}
.function_row .row {
  row-gap: 80px;
}
.sticky_img {
  position: sticky;
  top: 160px;
}
.signup_formBg {
  min-height: calc(100dvh - 113px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.outer_app .main_footer {
  background: #dbf3ff;
}
.outer_app .form-card {
  border-radius: 30px;
  max-width: 100%;
  width: 560px;
}

.outer_app .form-title {
  font-size: 30px;
  text-transform: uppercase;
  color: var(--purple-dark);
  font-weight: 700;
  margin: 0 0 20px;
  text-align: center;
}
.outer_app .form-social-buttons {
  max-width: unset;
}
.outer_app .form-social-buttons button {
  color: #1f193f !important;
  border-radius: 25px !important;
}
.outer_app .form-card form {
  margin: unset;
  max-width: unset;
}

.outer_app .form-card form button {
  border-radius: 25px;
}

.outer_app .form-card form .form-label {
  font-size: 16px;
  color: var(--purple-dark);
  font-weight: 600;
}
.outer_app .form-card form input {
  padding: 10px;
}
.outer_app .form-card form input,
.outer_app .form-card form textarea {
  background-color: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #cfcfcf;
}

.outer_app .form-card form input:focus {
  border-color: #00b0ef;
  background-color: #fff;
}

.outer_app .signup_formBg {
  background-image: url("../../public/images/new/signup-bg.png");
  padding: 35px 0;
  height: unset;
}

.outer_app .signup_formBg .form-card {
  margin-left: unset;
  margin-right: unset;
  padding: 30px;
}
.outer_app .or-text {
  padding-block: 20px 10px;
}
.outer_app .form-label {
  margin-bottom: 5px;
}

.outer_app .login_bg {
  background-image: url("../../public/images/new/login-bg.png");
  padding-block: 40px;
}
.outer_app #root {
  display: block;
  height: auto;
}
.inner_price_card.price_card {
  grid-template-columns: 100% !important;
  max-width: 1250px;
  top: -50px;
}
.inner_price_card.price_card .price_action .b_btn_white {
  background: #f2ce30;
  color: #313f7d !important;
  height: 51px !important;
  min-height: unset;
}

.grntImage img {
  max-width: 100%;
}
.grntImage {
  position: absolute;
  left: 70px;
  bottom: 60px;
  z-index: 1;
  padding: 14px;
  background-color: #fff;
  border-radius: 100%;
  width: 250px;
}
.inner_price_card .price_card_bg {
  padding: 60px 30px 60px 100px;
  grid-template-columns: 100% !important;
}
.inner_plan_section .price_title.hiw_title::after {
  right: 90px;
  top: 130px;
}
.inner_plan_section .hiw_title {
  margin-bottom: 180px;
}
.inner_plan_section.section-padding {
  padding-block: 135px;
}

@media (min-width: 768px) {
  .contact_info {
    max-height: calc(100% - 29px);
    position: relative;
    top: 29px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  /* --primaryColor: #812595; */
  --primaryColor: #1d97dc;
  --secondaryColor: #231f40;
  --hoverColor: #1d97dc;
  --textColor: #666666;
  --whiteColor: #ffffff;
  --lightBg: #f4f4f4;
  --darkColor: #333333;
  --lightBorderColor: #eeeeee;
  --greenColor: #54bb5e;
  --redColor: #e15858;
  --greyBg: #f4f6f8;
  /* --gradientButton: linear-gradient(93.3deg, var(--primaryColor) 1.91%, #F17444 100%); */
  --gradientButton: linear-gradient(
    93.3deg,
    var(--primaryColor) 1.91%,
    #45b1ee 100%
  );
  --gradientRevert: linear-gradient(
    93.3deg,
    #45b1ee 1.91%,
    var(--primaryColor) 100%
  );
  /* --gradientBg: linear-gradient(90.01deg, #fef0ec 0.41%, #f3e9f4 100%); */
  --gradientBg: linear-gradient(90.01deg, #f4f6f8 0.41%, #f4f6f8 100%);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

a {
  text-decoration: none;
}

:root {
  scroll-behavior: unset;
}

body,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  max-width: 1520px;
  padding-inline: 20px;
}

.innerContainer .container {
  max-width: 100%;
  padding-inline: 80px;
}

button:focus {
  box-shadow: none !important;
  outline: unset !important;
}

.section-padding {
  padding-block: 100px;
}

.headerMargin {
  margin-top: 100px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn:focus-visible,
.btn:first-child:active {
  box-shadow: none;
  outline: unset;
  border-color: inherit;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: var(--bs-btn-active-bg);
  border-color: inherit;
  color: var(--bs-btn-active-color);
}

.btn-check:checked + .btn:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
:not(.btn-check) + .btn:active:focus-visible {
  box-shadow: none;
}

/* input[type=number] {
    -moz-appearance: textfield;
} */

/* Global css Starts */
.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: var(--primaryColor);
  margin-bottom: 15px;
}

.main-title {
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  color: var(--secondaryColor);
  margin-bottom: 12px;
}

.main-title span {
  font-weight: bold;
}
.single-title {
  font-size: 36px;
  color: var(--whiteColor);
}

.text-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--textColor);
  margin: 0;
}

.btn-main-button,
.main-button {
  display: inline-block;
  background: var(--gradientButton);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--whiteColor);
  padding: 15px 29px;
  transition: all 0.4s ease;
  border: 1px solid var(--whiteColor);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.main-button.white-button {
  background: var(--whiteColor);
  color: var(--primaryColor);
}

.main-button:hover {
  color: var(--whiteColor);
  border-color: var(--whiteColor);
}

.btn-main-button:after,
.main-button:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  background: linear-gradient(
    93.3deg,
    var(--secondaryColor) 1.91%,
    var(--secondaryColor) 100%
  );
  left: 0;
  top: 0;
  z-index: -1;
  transition: all 0.4s ease;
  opacity: 0;
  visibility: hidden;
}

.btn-main-button:hover:after,
.main-button:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.noHover:hover::after {
  display: none;
}

.sub-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--secondaryColor);
  margin-bottom: 10px;
}

.check-icon-list {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
}

.check-icon-list li img {
  margin-right: 12px;
  position: absolute;
  left: 0;
  top: 12px;
  width: 20px;
}

.check-icon-list li {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: var(--textColor);
  position: relative;
  padding: 6px 0;
  padding-left: 29px;
}

.greyBg {
  background: var(--greyBg);
}

.sectionSpace {
  margin-bottom: 70px !important;
}

.maxW800 {
  max-width: 800px;
  margin: 0 auto;
}

.themeColor {
  color: var(--primaryColor) !important;
}

.lightBlueBg {
  background-color: #eaf4fb;
}
.blueGradient_sec .title-section {
  border-radius: 50px 0 0 50px;
  padding: 100px 60px 100px 100px;
  background-image: linear-gradient(-26deg, #0197da 0%, #09719f 30%);
}

/* Global css ends */

/* Header css start */
.siteHeader {
  padding: 25px 0;
  transition: all 0.4s ease;
  background-image: var(--gradientBg);
}

.siteInnerHeader {
  background-image: var(--gradientBg);
}

.siteHeader.whiteHeader {
  padding: 12px 0;
  background-image: var(--gradientBg);
}

.siteHeader.whiteHeader .white-button {
  border-color: var(--primaryColor);
  line-height: 17px;
}

.siteHeader .navbar-nav .nav-link {
  font-size: 18px;
  color: var(--secondaryColor);
  font-weight: 500;
  padding: 0 36px;
  font-style: normal;
  line-height: 22px;
}

.siteHeader .navbar-nav .nav-link:hover {
  color: var(--hoverColor);
}

.siteHeader .header-btn {
  margin-left: 16px;
}

.siteHeader .main-button {
  width: auto;
  padding-inline: 22px;
}

.siteHeader .main-button.white-button {
  margin-right: 15px;
}

.siteHeader .btn-main-button.white-button:hover {
  color: var(--whiteColor);
}

.header-btn .btn:hover {
  color: var(--whiteColor);
  background-color: inherit;
  border-color: inherit;
}

/* Header css end */

/* Hero Section css Starts */
.hero-section {
  background: var(--gradientBg);
  padding: 180px 0 90px;
}

.hero-section-image-area img {
  width: 100%;
}

.hero-section-content-area .sub-title {
  letter-spacing: 0.08em;
}

.hero-section-content-area .main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 120%;
  color: var(--secondaryColor);
}

.hero-section-content-area .text-paragraph {
  font-weight: 500;
  line-height: 28px;
  max-width: 639px;
  margin-bottom: 20px;
}

/* Hero Section css ends */

/* Course Category css Starts */

.side-bar-block:not(.active) .categoryLogo {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}

.two-block-image-area {
  padding-right: 70px;
}

.two-block-image-area.paddingLeft {
  padding-right: 0;
  padding-left: 70px;
}

.two-block-image-area img {
  width: 100%;
}

.two-block-content-area {
  padding-left: 25px;
  padding-top: 30px;
}

.two-block-content-area p:not(:last-child) {
  margin-bottom: 20px;
}

.icon-section {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.icon-area {
  background: var(--whiteColor);
  box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 20px;
  margin-right: 30px;
  flex: 0 0 100px;
  text-align: center;
}

.icon-area img {
  width: 60px;
}

.icon-content-area {
  flex: 0 0 75%;
}

.two-block-section .main-title {
  margin-bottom: 24px;
  font-weight: normal;
}
.two-block-section .main-title span {
  font-weight: bold;
}

.two-block-section.text-paragraph {
  margin: 0;
}

.two-block-section .row:not(:last-child) {
  margin-bottom: 100px;
}

.two-block-section .row:nth-child(even) {
  flex-direction: row-reverse;
}

.two-block-section .row:nth-child(even) .two-block-content-area {
  padding-left: 0;
}

.two-block-section .row:nth-child(even) .two-block-image-area {
  padding-right: 0;
  padding-left: 70px;
}
/* Course Category css Ends */

/* benefit Section css starts */
.benefits-section .title-section {
  margin-bottom: 70px;
}

.benefits-grid-box {
  background: var(--whiteColor);
  box-shadow: 0px 0px 80px rgb(0 0 0 / 6%);
  border-radius: 20px;
  padding: 40px 40px;
  margin: 0 8px;
  transition: all 0.4s ease;
  height: 100%;
}

.benefits-grid-box .sub-heading {
  margin: 25px 0 12px;
}

.benefits-grid-box .text-paragraph {
  line-height: 26px;
}

.benefits-grid-box:hover {
  background: var(--gradientButton);
  transform: translateY(-5px);
}

.benefits-grid-box:hover h3,
.benefits-grid-box:hover p {
  color: var(--whiteColor);
}

.benefits-grid-box .benefitsIcon {
  width: 100px;
}

.iconWrap {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border: 1px solid #1d97dc;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.benefits-grid-box .iconWrap .benefitsIcon {
  width: 75%;
}

.howItWorkCard .benefits-grid-box {
  padding: 30px 24px;
  margin: 0;
}

.step-title {
  font-weight: 600;
  color: var(--secondaryColor);
  font-size: 28px;
  text-align: center;
}

/* banefit Section Css Ends */

/* video section Css Starts */
.video-section .main-button {
  margin-top: 40px;
}

.videoCoverImage {
  border-radius: 20px;
  overflow: hidden;
}

.videoCoverImage iframe {
  vertical-align: middle;
}

.thumbnail-image img {
  cursor: pointer;
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.video-section .text-paragraph {
  max-width: 575px;
}

/* video section Css ends */

/* Registration section Css starts */
.registration-section-content-area {
  background: var(--gradientBg);
  border-radius: 20px;
  padding: 80px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 50px;
  flex-wrap: wrap;
}

.registration-section-content-area .main-title {
  margin-bottom: 0;
}

.registration-section-content-area .main-button {
  margin-top: 0;
}

/* Registration section Css ends */

/* Learn More Section Css Start */
.learnCard {
  overflow: hidden;
  border-radius: 50px;
}

.learnCard .learnCardTitle {
  text-align: center;
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 700;
}

.learnCardBody {
  padding: 32px;
  border-radius: 50px;
  margin-top: 15px;
  height: calc(100% - 51px);
}

/* .learnCardBody > div:not(:last-child) {
    margin-bottom: 15px;
} */

.learnCardBody p {
  font-size: 16px;
  line-height: 30px;
  color: #2a2a2a;
  padding: 5px 20px;
  margin-block: 3px;
  border-radius: 10px 10px 10px 10px;
  display: inline-block;
  width: auto;
  font-weight: 500;
  clear: both;
}

/* .learnCardBody .learnCardQue p {
    border-radius: 0px 10px 10px 10px;
    background: #FBFBFB;
}

.learnCardBody .learnCardAns p {
    border-radius: 0px 10px 10px 10px;
    background: #EAF4FB;
    margin-left: auto;
} */

/* Learn More Section Css Start */

/* Footer Css starts */
.main_footer {
  background: var(--lightBg);
  padding: 120px 0 40px;
  margin-top: auto;
}

.footer-link {
  display: grid;
  margin-top: 67px;
  grid-template-columns: auto auto auto;
}

.footer-link .nav-link {
  line-height: 1.4;
  padding: 7px 0;
  color: hsl(0, 0%, 40%);
}

.footer-link .nav-link svg path {
  fill: var(--textColor);
}

.footer-link .nav-link svg {
  width: 10px;
  margin-right: 5px;
}

.footer-social {
  display: flex;
  column-gap: 20px;
  margin-bottom: 24px;
}

.footer-social img {
  width: 38px;
}

/* Footer Css ends */

/* Inner Page Header css start */
.inner-page-padding {
  margin-block: 50px;
}

.inner-header {
  background: var(--gradientBg);
  padding: 22px 0;
  position: sticky;
  top: 0;
  z-index: 99;
}

.inner-header .container {
  position: relative;
}

.navbar-toggler {
  border: none;
  margin: 0 !important;
  padding-right: 0;
}

.offcanvasRight .offcanvas-body {
  padding: 0;
}

.offcanvasRight .offcanvas-body .nav-link {
  padding: 20px 16px;
  border-top: 1px solid var(--lightBorderColor);
  font-size: 16px;
  color: var(--secondaryColor);
  font-weight: 500;
  transition: 0.4s all;
}

.offcanvasRight .offcanvas-body .nav-link:hover,
.offcanvasRight .offcanvas-body .nav-link.active {
  color: var(--hoverColor);
}

.offcanvasRight .offcanvas-header {
  background-image: var(--gradientBg);
  padding: 20px 24px;
}

.inner-header .navbar-brand {
  margin-right: auto;
}

.user-name {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.user-name .user-img {
  border-radius: 50%;
  overflow: hidden;
  height: 56px;
  width: 56px;
  margin-right: 12px;
  background-color: var(--whiteColor);
}

.user-name .user-img img {
  width: 100%;
  height: 100%;
}

.user-name .user-img + span {
  background: transparent;
  color: var(--darkColor);
  font-size: 18px;
  font-weight: 500;
}

.back-link-header.inner-header {
  padding: 25px 0;
}

.inner-header .back-link {
  position: absolute;
  left: 20px;
  display: flex;
  align-items: center;
  color: var(--primaryColor);
  font-size: 16px;
  font-weight: 500;
  display: none;
}

.inner-header .back-link svg {
  margin-right: 12px;
}

.back-link-header.inner-header .back-link {
  display: flex;
}

.back-link-header.inner-header .container {
  position: relative;
  justify-content: center;
}

.back-link-header.inner-header .navbar-brand {
  margin-right: 0;
}

.back-link-header .user-name,
.back-link-header .navbar-toggler {
  display: none;
}

/* Inner Page Header css end */

/* Sign Up Form css starts */
.form-card {
  text-align: center;
  background-image: var(--gradientBg);
  border-radius: 20px;
  max-width: 510px;
  margin: 0 auto;
  padding: 50px;
  width: 100%;
}

.form-title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: var(--primaryColor);
  margin: 0 0 20px;
}

.form-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  color: var(--textColor);
  margin: 0 auto 45px;
}

.form-social-buttons {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}

.form-social-buttons > div:not(:last-child) {
  margin-bottom: 15px;
}

.form-social-buttons button {
  margin: 0 !important;
  box-shadow: none !important;
  color: var(--textColor) !important;
  font-size: 16px !important;
  position: relative;
  font-family: "Inter", sans-serif;
  width: 100% !important;
  border-radius: 5px !important;
}

.form-social-buttons div:first-child button {
  color: var(--whiteColor) !important;
}

.form-social-buttons button div div:first-child {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%) !important;
}

.form-social-buttons button div {
  text-align: center !important;
}

.form-social-buttons button div div:nth-child(2) {
  display: none;
}

.form-bottom-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 20px;
  color: var(--secondaryColor);
}

.form-card .social-btn {
  max-width: 400px;
  margin: 0 auto;
  padding: 15px 0;
  background: var(--whiteColor);
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--textColor);
  position: relative;
  border: none;
}

.form-card .social-btn:not(:last-child) {
  margin-bottom: 20px;
}

.social-btn img {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.social-btn:hover {
  color: var(--textColor);
}

.social-btn.facebook-button {
  background: #3b5998;
  color: var(--whiteColor);
}

.social-btn.facebook-button:hover {
  color: var(--whiteColor);
}

.or-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--secondaryColor);
  padding-block: 20px;
  display: inline-block;
}

.iconForm {
  position: relative;
}

.iconForm .form-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  color: var(--textColor);
  cursor: pointer;
}

.form-card form {
  margin: 0 auto;
  max-width: 400px;
}

.form-card .form-group {
  margin: 0 0 15px;
}

span.validation-msg {
  text-align: left;
  width: 100%;
  display: block;
  font-size: 14px;
  color: var(--redColor);
  margin: 4px 0 0 2px;
}

.form-card form input,
.form-card form button {
  margin: 0 auto;
  width: 100%;
}

.form-card form button {
  margin-bottom: 0;
}

.form-card form input,
.form-card form textarea {
  background-color: var(--whiteColor);
  padding: 14px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid transparent;
  color: var(--textColor);
}

.form-card form input:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
}

.form-card form .btn-main-button {
  border: none;
  line-height: 19px;
}

.form-card form .btn-main-button:hover {
  color: var(--whiteColor);
}

.form-bottom-text a,
.forgot-password-link {
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  color: var(--primaryColor);
  display: inline-block;
  cursor: pointer;
}

.optInput {
  text-align: center;
  font-size: 18;
  font-weight: 600;
  color: var(--darkColor);
}

.signup_formBg {
  background-image: url(../public/images/New_York-min.jpg);
  background-size: cover;
  background-position: center;
  padding: 35px;
  height: 100%;
}

.signup_formBg .form-card {
  margin-left: auto;
  margin-right: 0;
  padding: 25px 40px;
}

.login_bg {
  background-image: url(../public/images/London.jpg);
}

/* Sign Up Form css ends */

/* User Dashboard */
.user-dashboard {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  column-gap: 30px;
}

.side-bar {
  flex-basis: 300px;
  background: var(--gradientBg);
  padding: 30px;
  border-radius: 20px;
  min-height: calc(100vh - 200px);
}

.side-bar-block {
  padding: 20px;
  background-color: var(--whiteColor);
  text-align: center;
  border-radius: 5px;
  background: linear-gradient(white, white) padding-box,
    var(--gradientButton) border-box;
  border: 1px solid transparent;
  cursor: not-allowed;
  pointer-events: none;
}

.side-bar-block.lessonCompleted {
  cursor: pointer;
  pointer-events: unset;
}

.side-bar-block.active,
.side-bar-block.lessonCompleted:hover {
  background: var(--gradientButton);
  cursor: pointer;
  pointer-events: unset;
}

.side-bar-block.active h4,
.side-bar-block.lessonCompleted:hover h4 {
  color: var(--whiteColor);
}

.side-bar-block.active img,
.side-bar-block.lessonCompleted:hover img {
  filter: brightness(100);
}

.side-bar-block:not(:last-child) {
  margin-bottom: 20px;
}

.side-bar-block h4 {
  color: var(--primaryColor);
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.side-bar-block img {
  width: 55px;
}

.user-detail-area {
  flex-basis: calc(100% - 330px);
  background: var(--gradientBg);
  padding: 45px 40px;
  border-radius: 20px;
  min-height: calc(100vh - 200px);
}

.user-detail-image-area img {
  width: 100%;
}

.user-detail-content-area h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 10px;
  color: var(--darkColor);
}

.user-detail-content-area h2 {
  font-weight: 600;
  font-size: 34px;
  line-height: 1.6;
  text-align: center;
  color: var(--primaryColor);
  margin-bottom: 10px;
}

.user-detail-content-area p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--textColor);
  margin-bottom: 18px;
}

.user-detail-content-area p span {
  font-weight: 600;
  font-size: 16px;
  line-height: 44px;
  text-align: center;
  color: var(--darkColor);
}

.user-detail-content-area .main-button {
  margin-top: 15px;
}

/* Question Screen */
.que-container {
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
}

.que-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.que-title span {
  color: var(--darkColor);
  margin: 0 7px;
  font-size: 15px;
  font-weight: 500;
}

.que-title span.que-dot {
  font-size: 4px;
  margin: 0 2px;
  color: var(--textColor);
}

.text-area {
  text-align: center;
  background: #fff;
  line-height: 1.4;
  margin-bottom: 20px;
  font-size: 16px;
  color: var(--textColor);
  border-radius: 5px;
  padding-inline: 20px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-area.text-play-wrap {
  padding: 10px 0;
  min-height: 70px;
}

.text-play {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-play audio {
  width: 100%;
}

.play-icon {
  width: 40px;
  height: 40px;
  background: #c7c7c7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  margin-right: 20px;
}

.play-icon .fa-play {
  padding-left: 3px;
}

.play-bar {
  width: 200px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 8px;
  position: relative;
}

.play-bar-dot {
  position: absolute;
  width: 16px;
  height: 16px;
  top: -4px;
  margin-left: -5px;
  background: #d9d9d9;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
}

.hableQueScroll {
  margin-bottom: 15px;
}

.play-icon.active,
.play-icon.active + .play-bar {
  background-image: var(--gradientButton);
}

.play-icon.active + .play-bar .play-bar-dot {
  background-color: var(--whiteColor);
  border-color: var(--primaryColor);
}

.yesNoWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 186px;
  margin: 0 auto;
}

.user-detail-area .main-button.no-btn,
.user-detail-area .main-button.yes-btn {
  min-width: 88px;
  text-align: center;
  transition: none;
}

.user-detail-area .main-button.no-btn::after,
.user-detail-area .main-button.yes-btn::after {
  display: none;
}

.user-detail-area .main-button.no-btn {
  background: var(--redColor);
}

.user-detail-area .main-button.yes-btn {
  background: var(--greenColor);
}

.que-dots {
  text-align: center;
  margin: 0 0 30px;
}

.que-dots span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  margin: 0 5px;
  border-radius: 50%;
  cursor: not-allowed;
  pointer-events: none;
  border: 1px solid transparent;
  position: relative;
}

.que-dots span.wrong {
  background: var(--redColor);
  cursor: pointer;
  pointer-events: unset;
}

.que-dots span.right {
  background: var(--greenColor);
  cursor: pointer;
  pointer-events: unset;
}

.que-dots span.active {
  border-color: var(--primaryColor);
  background: transparent;
  cursor: pointer;
  pointer-events: unset;
}

.que-dots span.active:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--primaryColor);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress-horizontal {
  max-width: 830px;
  margin: 0 auto 30px;
}

.progress-horizontal .progress {
  background-color: #fff;
  border-radius: 10px;
}

.progress-horizontal .progress-bar {
  border-radius: 10px;
  background: var(--gradientButton);
}

.note-area {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  border-radius: 5px;
  padding: 20px 36px;
  font-size: 16px;
  color: #666;
  line-height: 1.8;
  position: relative;
}

.note-area.grammatical-note {
  margin: 0;
  height: 100%;
}

.note-area.grammatical-note .grammaticalContentScroll {
  margin-bottom: 50px;
}

.note-area svg {
  position: absolute;
  top: 16px;
  left: 16px;
}

.note-area img {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 26px;
}

.que-container .carousel-control-next,
.que-container .carousel-control-prev {
  width: 60px;
  height: 60px;
  top: 205px;
  background: var(--gradientButton);
  border-radius: 50%;
  border: 1px solid #d4d4d4;
}

.que-container .carousel-control-prev {
  left: -80px;
}

.que-container .carousel-control-next {
  right: -80px;
}

.hableQueWrap {
  max-height: 510px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 10px;
}

.hableQueScroll > div {
  max-height: 532px;
}

.habla-outer {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.hable-des {
  padding-inline: 90px;
  /* max-width: 880px; */
  white-space: pre-wrap;
  margin: 0 auto;
}

.habla-uere-img {
  border-radius: 50%;
  height: 70px;
  width: 70px;
  position: absolute;
  left: 0;
  background-color: var(--whiteColor);
}

.habla-uere-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.habla-uere-img span {
  background: var(--greenColor);
  height: 20px;
  width: 20px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 20px;
  color: var(--whiteColor);
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.habla-uere-img span.wrong {
  background-color: var(--redColor);
}

.habla-outer .text-block {
  padding-inline: 90px;
}

.habla-page .text-area {
  text-align: left;
  margin: 0;
  border-radius: 0;
  line-height: 60px;
}

.habla-page .text-area:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.habla-outer:nth-child(even) .habla-uere-img span {
  left: 4px;
  right: auto;
}

.habla-outer:nth-child(even) {
  flex-direction: row-reverse;
}

.habla-outer:nth-child(even) .habla-uere-img {
  left: auto;
  right: 0;
}

.habla-page .habla-outer:nth-child(even) .text-area {
  justify-content: end;
}

.habla-page .habla-outer .text-area {
  justify-content: start;
}

.habla-page .habla-outer .text-area.text-play-wrap {
  justify-content: center;
}

.que-container .carousel {
  width: calc(100% - 120px);
  margin: 0 auto;
}

/*.habla-outer .text-area.text-play-wrap {
    justify-content: flex-start;
    padding-left: 16px;
}

.habla-outer:nth-child(even) .text-area.text-play-wrap {
    justify-content: flex-end;
    padding-right: 16px;
}*/

/* Score Page */
.score-area {
  max-width: 560px;
  margin: 0 auto 40px;
}

.score-area .title {
  color: var(--primaryColor);
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.score-area p {
  color: var(--darkColor);
  font-size: 22px;
}

.percent-div {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px auto;
  width: 200px;
}

.percent-div .CircularProgressbar {
  height: 154px;
  width: 154px;
  margin: 0 auto;
}

body .CircularProgressbar .CircularProgressbar-text {
  background: var(--gradientButton);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 21px;
  font-weight: 700;
  fill: var(--primaryColor);
}

body .CircularProgressbar .CircularProgressbar-path {
  stroke: var(--primaryColor);
}

body .CircularProgressbar .CircularProgressbar-trail {
  stroke: #ddd;
}

body .CircularProgressbar .CircularProgressbar-path {
  stroke: url(#gradientId) !important;
}

.divider-div {
  width: 100%;
  max-width: 512px;
  background: var(--primaryColor);
  height: 2px;
  margin: 20px auto;
}

/* Achievements Page */
.achievements-section {
  margin-inline: auto;
  max-width: 1130px;
  background-image: var(--gradientBg);
  border-radius: 10px;
  padding: 40px 60px 150px;
}

.tab-card {
  padding: 20px;
  background: var(--whiteColor);
  font-size: 18px;
  line-height: 28px;
  margin: 55px auto;
  text-align: center;
  border-radius: 10px;
}

.achievements-section .nav-tabs {
  justify-content: center;
  border: none;
}

.achievements-section .nav-item {
  margin: 7px;
}

.achievements-section .nav-link {
  position: relative;
  border-radius: 5px;
  background: var(--whiteColor);
  color: var(--primaryColor);
  font-weight: 600;
  background-clip: padding-box;
  padding: 15px 40px;
  background: linear-gradient(white, white) padding-box,
    var(--gradientButton) border-box;
  border: 1px solid transparent;
}

.achievements-section .nav-link.active,
.achievements-section .nav-link:hover {
  color: var(--whiteColor);
  background: var(--gradientButton);
  isolation: inherit;
  border: 1px solid transparent;
}

.tab-content .CircularProgressbar {
  height: 144px;
  width: 144px;
}

.tab-card h3 {
  font-size: 18px;
  color: var(--darkColor);
}

.tab-card .tab-card-circle {
  width: 125px;
  height: 125px;
  background: linear-gradient(white, white) padding-box,
    var(--gradientButton) border-box;
  border: 8px solid transparent;
  margin: 30px auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-card .tab-card-circle span {
  font-size: 36px;
  font-weight: 700;
  color: var(--primaryColor);
}

.tab-card .box {
  background: var(--whiteColor);
  border-radius: 5px;
  padding: 8px;
  background-color: var(--lightBg);
  text-align: center;
  width: 122px;
}

.tab-card .box p {
  font-size: 12px;
}

.linear-bg-text {
  background: linear-gradient(91deg, var(--primaryColor), #45b1ee 80%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tab-card .box p:first-child {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.tab-card .box.bg-gradient {
  color: var(--whiteColor);
  margin-left: 6px;
  background-image: linear-gradient(
    180deg,
    var(--primaryColor) 0%,
    #45b1ee 100%
  ) !important;
}

/* Achievements Lesson */
.main-milestone {
  max-width: 807px;
  width: 100%;
  margin: 50px auto 0;
  padding: 0 10px;
}

.milestone {
  width: 100%;
  padding: 14px 10px 10px;
  margin-bottom: 100px;
  position: relative;
  height: 84px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  background: var(--gradientButton);
}

.milestone:last-child {
  margin-bottom: 45px;
}

.milestone-1 {
  background: #d2a8d8;
  padding-left: 40px;
}

/* .milestone:nth-child(odd) {
    background: #d2a8d8;
}

.milestone:nth-child(even) {
    background: #eeaec1;
} 

.milestone:last-child {
    background: #ffb07e;
} */

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #333333;
  display: inline-block;
}

.circles {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.milestone:nth-child(even) .circles {
  flex-direction: row-reverse;
}

.content {
  text-align: center;
}

.shape {
  width: 110px;
  height: 84px;
  position: absolute;
  background: var(--gradientButton);
  top: 0;
  left: -110px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  padding-left: 40px;
}

.shape:after {
  content: "";
  position: absolute;
  left: -1px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 32px solid #45b1ee;
  border-top: 42px solid transparent;
  border-bottom: 42px solid transparent;
}

.shape:before {
  content: "";
  position: absolute;
  right: -42px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 42px solid #45b1ee;
  border-top: 42px solid transparent;
  border-bottom: 42px solid transparent;
}

.milestone:last-child .shape {
  left: auto;
  right: -80px;
  z-index: -1;
  border-left-color: #ffb07e !important;
}

.milestone:first-child .shape:after {
  border-left: 32px solid #f4f6f8;
}

.content p {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  margin-top: 1px;
}

.round-shape {
  position: absolute;
  right: auto;
  top: 0;
  height: 268px;
  width: 120px;
  left: 100%;
  bottom: auto;
  background: none;
  margin-left: -5px;
}

.round-shape::before,
.round-shape::after {
  content: "";
  width: 100%;
  bottom: 0;
  top: auto;
  left: 0;
  right: 0;
  height: 134px;
  background: var(--gradientRevert);
  z-index: 1;
  position: absolute;
  border-bottom-right-radius: 200px;
}

.round-shape::after {
  content: "";
  position: absolute;
}

.milestone::after {
  content: "";
  width: 100px;
  height: 100px;
  z-index: 3;
  position: absolute;
  right: 0;
  top: 100%;
  background: #f4f6f8;
  border-radius: 100%;
  margin: 0 -40px 0 0;
}

.milestone:last-child::after {
  display: none;
}

.round-shape::before {
  background: var(--gradientRevert);
  top: 0;
  bottom: auto;
  border-top-right-radius: 200px;
  border-bottom-right-radius: 0;
}

.milestone:nth-child(even) .round-shape {
  right: 100%;
  left: auto;
  transform: rotate(180deg);
  margin-right: -5px;
}

.milestone:nth-child(even):after {
  left: 0;
  right: 100%;
  margin: 0 0 0 -40px;
  background-color: #f4f6f8;
}

.milestone:nth-last-child(2) .round-shape::before {
  background: #ffb07e;
}

.round-shape::before,
.round-shape::after {
  left: -1px;
}

.milestone:nth-child(2n) .round-shape::before {
  background: var(--gradientButton);
}

.milestone:nth-child(2n) .round-shape::after {
  background: var(--gradientButton);
}

.lession-note {
  color: var(--darkColor);
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

/* Plan Page */
.plan-section {
  margin-inline: auto;
  max-width: 100%;
  background-image: var(--gradientBg);
  border-radius: 10px;
  padding: 80px;
}

.plan-section .title {
  font-size: 32px;
  text-align: center;
  color: var(--primaryColor);
  line-height: 1.6;
  font-weight: 600;
}

.plan-section p {
  color: var(--textColor);
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
}

.plan-card-row {
  margin-top: 65px;
}

.plan-card {
  padding: 30px;
  background-color: var(--whiteColor);
  border-radius: 10px;
  margin-block: 10px;
  position: relative;
}

.plan-title {
  background: var(--gradientButton);
  display: inline-block;
  line-height: 50px;
  padding: 0 60px 0 30px;
  border-radius: 0px 30px 30px 0px;
  margin-left: -30px;
  font-weight: 600;
  font-size: 18px;
  color: var(--whiteColor);
}

.plan-price {
  color: var(--darkColor);
  font-size: 30px;
  font-weight: 600;
  margin: 24px 0 10px;
  text-align: center;
  line-height: 1.4;
}

.plan-price span {
  bottom: 1px;
  color: var(--textColor);
  font-size: 16px;
  display: block;
  font-weight: 500;
  margin: 0;
}

.plan-price sup img {
  width: 14px;
}

.plan-card .check-icon-list {
  margin-bottom: 16px;
}

.plan-card .check-icon-list li {
  font-weight: 400;
  max-width: 250px;
  margin: 0 auto;
  padding-block: 16px;
  text-align: center;
  padding-inline: 0;
}

.plan-card .check-icon-list li:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.plan-card .check-icon-list li img {
  width: 18px;
}

.cardDiscount {
  /* background: var(--gradientButton); */
  background: #f4a92c;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  color: #fff;
  line-height: 1.1;
  font-weight: 600;
  font-size: 15px;
  position: absolute;
  top: -40px;
  right: 30px;
  border: 2px dotted #fff;
}

.cardDiscount span {
  display: block;
  font-size: 27px;
  color: #fff;
  font-weight: bold;
}

/* Profile Screen */
.profile-img {
  width: 140px;
  margin: 0 auto 30px;
  height: 140px;
  display: block;
  border-radius: 50%;
  position: relative;
  background-color: var(--whiteColor);
}

.profile.form-card form input {
  pointer-events: none;
}

.profile-img img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.editIcon {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 5px;
  z-index: 2;
  right: 10px;
  background: var(--gradientButton);
  color: var(--whiteColor);
  border-radius: 5px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}

.profile-img input.form-control {
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  position: absolute;
  bottom: -10px;
  right: -10px;
  border-radius: 50%;
  z-index: 9;
  opacity: 0;
}

.edit-profile.form-card form input {
  color: var(--darkColor);
  border-color: #aeaee4;
}

.form-control:disabled {
  background-color: #dfdfdf;
  opacity: 1;
  border-color: #dfdfdf !important;
}

/* loader Css Start */
.loaderWrap {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-main {
  border: 2px solid;
  border-color: transparent var(--primaryColor);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  -webkit-animation: rotation 2s linear infinite;
  animation: rotation 2s linear infinite;
}

.loader-main:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border: 50px solid;
  border-color: transparent rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderOuter {
  position: fixed;
  background: rgba(255, 255, 255, 0.95);
  height: 100%;
  width: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderInner {
  text-align: center;
}

.loaderInner img {
  -webkit-animation: jump 2s ease-in infinite alternate;
  animation: jump 2s ease-in infinite alternate;
  margin-bottom: 36px;
}

@-webkit-keyframes jump {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(5px);
  }

  75% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(15px);
  }
}

@keyframes jump {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(5px);
  }

  75% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(15px);
  }
}

.loading {
  display: flex;
  flex-direction: row;
}

.loading__letter {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--primaryColor);
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.loading__letter:nth-child(2) {
  animation-delay: 0.1s;
}

.loading__letter:nth-child(3) {
  animation-delay: 0.2s;
}

.loading__letter:nth-child(4) {
  animation-delay: 0.3s;
}

.loading__letter:nth-child(5) {
  animation-delay: 0.4s;
}

.loading__letter:nth-child(6) {
  animation-delay: 0.5s;
}

.loading__letter:nth-child(7) {
  animation-delay: 0.6s;
}

.loading__letter:nth-child(8) {
  animation-delay: 0.8s;
}

.loading__letter:nth-child(9) {
  animation-delay: 1s;
}

.loading__letter:nth-child(10) {
  animation-delay: 1.2s;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }

  40% {
    transform: translateY(-16px);
  }

  80%,
  100% {
    transform: translateY(0px);
  }
}

/* Loader Css End */

/* Model */
.modal-backdrop.show {
  opacity: 0.6;
}

.common-popup .modal-header {
  border: none;
  padding-bottom: 0;
}

.common-popup .model-title {
  font-size: 16px;
  color: var(--darkColor);
  margin-bottom: 20px;
}

.model-btn {
  padding: 30px;
}

.common-popup .main-button {
  width: 48%;
  margin: 1%;
  display: inline-block;
  padding: 15px 10px;
}

.model-btn .main-button:first-child {
  background: var(--whiteColor);
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  line-height: 17px;
}

.model-btn .main-button:first-child:hover {
  color: var(--whiteColor);
}

.timerPopup .modal-dialog {
  max-width: 530px;
}

.connectaResumePopup,
.timerPopup {
  background: rgba(0, 0, 0, 0.7);
}

.welcom_popup .side-bar-block h4 {
  margin-top: 0;
}

.connectaResumePopup .modal-content {
  left: 120px;
}

/* Testimonial */
.testimonial-box {
  padding: 40px;
  background-image: var(--gradientBg);
  margin-bottom: 24px;
  border-radius: 20px;
  transition: 0.4s all;
}

.testimonial-box:hover {
  background-image: linear-gradient(90.01deg, #f3e9f4 0.41%, #fef0ec 100%);
}

.testimonial-box p {
  font-size: 16px;
  line-height: 30px;
  color: var(--textColor);
  padding: 20px 0 16px 0;
}

.rating i {
  color: var(--primaryColor);
}

.clientName {
  font-size: 20px;
  line-height: normal;
  color: var(--darkColor);
  font-weight: bold;
  margin-top: 16px;
}

/* Pagination */
.paginationWrap .page-item .page-link {
  height: 40px;
  width: 40px;
  text-align: center;
  color: var(--darkColor);
  border: none;
  margin: 0 5px;
  border-radius: 4px;
}

.paginationWrap .page-item .page-link:hover {
  background-image: var(--gradientBg);
}

.paginationWrap .page-item.active .page-link {
  background-image: var(--gradientButton);
  color: var(--whiteColor);
}

/* Content Pages */
.content-page {
  max-width: 1200px;
  margin: 0 auto;
}

.subTitle {
  font-size: 24px;
  font-weight: 600;
  color: var(--darkColor);
  margin: 20px 0 10px;
}

.content-page p {
  font-size: 16px;
  color: var(--textColor);
  line-height: 1.6;
  margin-bottom: 16px;
}

/* Contact Us Page */
/* .contact-page .form-card, .contact-page .form-card form {
    max-width: 100%;
    text-align: left;
}

.contact-page .form-title {
    text-align: left;
}

.contact-page .form-card form button {
    width: auto;
} */

.contact-page .main-title + .text-paragraph {
  margin-bottom: 60px;
}

.emailLink {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
}

.emailLink img {
  width: 26px;
}

/* Faq Page */
.faqWrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.faqWrapper .accordion-body,
.faqWrapper .accordion-body p {
  color: var(--textColor);
  font-size: 16px;
  line-height: 1.4;
}

.faqWrapper .accordion-item {
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid var(--lightBorderColor);
}

.faqWrapper .accordion-button {
  background-image: var(--gradientBg);
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 18px;
}

.faqWrapper .accordion-button[aria-expanded="false"] {
  border-radius: 10px;
}

.faqWrapper .accordion-button[aria-expanded="true"] {
  border-radius: 10px 10px 0 0;
  background-image: var(--gradientButton);
  color: var(--whiteColor);
}

.faqPage .two-block-content-area p {
  margin-bottom: 50px;
}

.faqPage .two-block-content-area {
  padding-left: 0;
  padding-top: 0;
}

.faqPageTitle {
  position: relative;
  margin-bottom: 100px;
}

/* Checkout Page */
.checkout-form .form-card form button {
  margin-top: 20px;
}

.checkout-page-form {
  max-width: 100%;
  background-image: none;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  column-gap: 30px;
}

.side-plan-detail {
  flex-basis: 600px;
  background: var(--gradientBg);
  padding: 30px;
  border-radius: 20px;
  min-height: calc(100vh - 200px);
  text-align: left;
}

.side-plan-detail .img-thumb {
  text-align: center;
  margin-top: 50px;
}

.side-plan-detail .price {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 41px;
  color: var(--primaryColor);
  margin: 0 0 10px;
}

.side-plan-detail .price span {
  font-size: 16px;
  font-weight: 400;
}

.side-plan-detail p {
  font-size: 16px;
  margin-block: 10px;
}

.back-link {
  color: var(--primaryColor);
}

.back-link i {
  margin-right: 5px;
}

.payment-details-section {
  flex-basis: calc(100% - 630px);
  background: var(--gradientBg);
  padding: 45px 40px;
  border-radius: 20px;
  min-height: calc(100vh - 200px);
}

.payment-details-section label {
  display: block;
  text-align: left;
  font-size: 14.88px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.payment-details-section .p-Field {
  margin-bottom: 10px;
  position: relative;
}

.payment-details-section .p-Field .cardIcon {
  position: absolute;
  top: 32px;
  right: 12px;
}

.card-number-element {
  padding: 12px;
  background-color: var(--colorBackground);
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
}

.card-address-element .Error {
  display: none;
}

.payment-details-section .card-address-element .Input:focus,
.payment-details-section .p-Input--focused {
  outline: 0;
  border-color: transparent;
  box-shadow: none;
}

.has-error {
  border: 3px solid #df1b41;
}

.error {
  color: #df1b41;
  font-size: 14px;
  text-align: left;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.card_details_section {
  margin: 0 -5px;
}

.card_details_section .col-md-6 {
  padding-inline: 5px;
}

.checkout-form.inner-page-padding {
  margin: 0;
}

/* Toast Message */
.Toastify__toast {
  position: absolute !important;
  width: 100%;
}

/*Welcome page popup*/
.showWelcomeContent {
  display: none;
}

.welcom_popup .modal-dialog {
  max-width: 900px;
  position: relative;
  left: 120px;
  width: 96%;
}

.welcom_popup.common-popup .main-button {
  width: auto;
  padding: 15px 40px;
}

.welcom_popup .modal-body {
  padding: 90px 80px;
}

.welcom_popup .model-btn .popup-buttons {
  width: auto;
  display: inline-block;
  padding: 15px 30px;
  margin: 0 15px 15px 0;
}

/* Table */
.grammaticalContent {
  max-width: 100%;
  margin: 0 auto;
}

.grammaticalContentScroll {
  /* max-height: 560px; */
  /* overflow-y: auto; */
  margin-bottom: 20px;
  padding-right: 10px;
}

.grammaticalContent table,
.note-area table {
  border: 1px solid #ddd;
  margin: 20px 0;
}

.grammaticalContent table td,
.note-area table td {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

.grammaticalContent table td:not(:last-child),
.note-area table td:not(:last-child) {
  border-right: 1px solid #ddd;
}

.note-area table {
  margin: 0 auto;
}

.grammaticalContent p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--textColor);
  margin-bottom: 18px;
  text-align: center;
  word-break: break-word;
}

.grammaticalContent h2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 10px;
  color: var(--darkColor);
}

.user-detail-area .grammaticalContent .main-button {
  background: var(--gradientButton);
  font-size: 24px;
  padding: 0;
  line-height: 44px;
  border-radius: 50%;
  padding-left: 2px;
  height: 44px;
  width: 44px;
}

.textWidth {
  max-width: 600px;
  margin: 0 auto 18px;
}

.textWidth.textWidth01 {
  max-width: 510px;
}

.textWidth.textWidth02 {
  max-width: 800px;
}

.strikethrough {
  text-decoration: line-through;
  color: var(--redColor);
}

/* Responsive Css start */
@media (min-width: 992px) {
  .siteHeader .offcanvasRight .offcanvas-body .nav-link {
    padding-block: 16px;
    border-top: none;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 992px) {
  .text-area {
    min-height: 45px;
  }

  .hableQueWrap {
    max-height: 420px;
  }
}

@media (max-width: 1700px) {
  .note-area table {
    width: max-content !important;
    width: auto !important;
  }
}

@media (max-width: 1439.98px) {
  .hero-section-content-area .main-title {
    font-size: 50px;
  }

  .que-container .carousel-control-prev {
    left: -50px;
  }

  .que-container .carousel-control-next {
    right: -50px;
  }

  .que-container .carousel-control-next,
  .que-container .carousel-control-prev {
    width: 40px;
    height: 40px;
    top: 215px;
  }

  .que-container .carousel {
    width: calc(100% - 40px);
  }

  .welcom_popup .modal-dialog {
    left: 0;
  }

  .connectaResumePopup .modal-content {
    left: 0px;
  }
}

@media (max-width: 1199.98px) {
  .innerContainer .container {
    padding-inline: 20px;
  }

  .hero-section-content-area .main-title {
    font-size: 42px;
  }

  .siteHeader .main-button {
    padding: 15px 16px;
    font-size: 15px;
  }

  .hero-section-content-area .text-paragraph {
    margin-bottom: 20px;
  }

  .hero-section {
    padding: 120px 0 50px;
  }

  .benefits-section .title-section {
    margin-bottom: 40px;
  }

  .siteHeader .navbar-nav .nav-link {
    font-size: 14px;
    padding: 0 7px;
  }

  .siteHeader .main-button.white-button {
    margin-right: 10px;
  }

  .siteHeader .header-btn {
    margin-left: 10px;
  }

  .section-padding {
    padding-block: 60px;
  }

  .main-title {
    font-size: 36px;
  }

  .two-block-section .row:not(:last-child) {
    margin-bottom: 70px;
  }

  .benefits-grid-box {
    padding: 24px;
    margin: 0;
  }

  .registration-section-content-area {
    padding: 60px 24px;
  }

  .achievements-section {
    padding: 30px 24px 100px;
  }

  .tab-card {
    margin: 30px auto;
    padding: 24px;
  }

  .tab-card .tab-card-circle {
    margin: 20px auto;
  }

  .main-milestone {
    max-width: 720px;
  }

  .payment-details-section {
    flex-basis: calc(100% - 430px);
  }

  .side-plan-detail {
    flex-basis: 400px;
  }

  .grammaticalContent h2 {
    font-size: 20px;
  }

  .howItWorkCard.benefitsRow > div:not(:last-child) {
    margin-bottom: 20px;
  }

  .plan-card-row > div:not(:last-child) .plan-card {
    margin-bottom: 50px;
  }
}

@media (max-width: 991.98px) {
  .hero-section-content-area .main-title {
    font-size: 32px;
  }

  .plan-section {
    padding: 30px 30px;
  }

  .main-title {
    font-size: 32px;
  }

  .sub-heading {
    font-size: 20px;
  }

  .user-detail-area,
  .side-bar {
    flex-basis: 100%;
    padding: 24px;
    min-height: auto;
  }

  .side-bar {
    margin-bottom: 20px;
  }

  .main-title {
    font-size: 32px;
  }

  .icon-area {
    padding: 20px;
    flex: 0 0 80px;
    margin-right: 20px;
  }

  .icon-area img {
    width: 40px;
  }

  .contact-page .main-title + .text-paragraph {
    margin-bottom: 40px;
  }

  .sub-heading {
    font-size: 18px;
    margin-bottom: 6px;
  }

  .headerMargin {
    margin-top: 90px;
  }

  .subTitle {
    font-size: 20px;
  }

  .btn-main-button,
  .main-button {
    font-size: 15px;
    padding: 12px 24px;
  }

  .form-card form input {
    padding: 11px 20px;
    font-size: 15px;
  }

  .inner-header,
  .back-link-header.inner-header {
    padding: 18px 0;
  }

  .inner-header .navbar-brand img {
    width: 150px;
  }

  .user-name {
    margin-right: 4px;
  }

  .user-name .user-img {
    height: 40px;
    width: 40px;
    margin-right: 6px;
  }

  .user-name .user-img + span {
    font-size: 16px;
  }

  .two-block-image-area {
    padding: 0 !important;
  }

  .two-block-image-area.paddingLeft {
    padding: 0 !important;
  }

  .two-block-content-area {
    padding-left: 0;
    padding-top: 40px;
  }

  .two-block-section .row:not(:last-child) {
    margin-bottom: 50px;
  }

  .two-block-section .main-title {
    margin-bottom: 20px;
  }

  .form-card {
    padding: 36px;
  }

  .or-text {
    padding-block: 30px;
  }

  .form-bottom-text {
    margin-top: 30px;
    font-size: 15px;
  }

  .form-social-buttons button {
    height: 45px !important;
  }

  .footer-social {
    column-gap: 16px;
  }

  .video-section .main-button {
    margin-top: 20px;
  }

  .video-section .row {
    flex-direction: column-reverse;
  }

  .video-section-content-area {
    margin-top: 30px;
  }

  .user-detail-content-area h4 {
    font-size: 18px;
  }

  .user-detail-content-area h2 {
    font-size: 24px;
  }

  .user-detail-content-area .main-button {
    margin-top: 0;
  }

  .note-area svg {
    top: 5px;
    left: 5px;
  }

  .main-milestone {
    max-width: 520px;
  }

  .content p {
    font-size: 10px;
  }

  .circle {
    width: 36px;
    height: 36px;
    line-height: 35px;
    font-size: 10px;
  }

  .milestone {
    padding: 17px 10px 10px;
  }

  .shape:before {
    right: -25px;
    border-left: 25px solid #45b1ee;
  }

  .que-container .carousel {
    width: calc(100% - 70px);
  }

  .side-plan-detail {
    flex-basis: 300px;
    max-width: 300px;
  }

  .payment-details-section {
    flex-basis: calc(100% - 330px);
  }

  .welcom_popup .modal-body {
    padding: 30px;
  }

  .step-title {
    font-size: 20px;
  }

  .sectionSpace {
    margin-bottom: 40px !important;
  }

  .faqPageTitle {
    margin-bottom: 70px;
  }
}

@media (max-width: 767.98px) {
  .navbar-brand img {
    width: 150px;
  }

  .siteHeader {
    padding: 20px 0;
  }

  .user-name .user-img + span {
    font-size: 14px;
  }

  .headerMargin {
    margin-top: 80px;
  }

  .section-padding {
    padding-block: 40px;
  }

  .plan-card-row {
    margin-top: 12px;
  }

  .hero-section .row {
    flex-direction: column-reverse;
  }

  .hero-section {
    padding: 90px 0 40px;
  }

  .hero-section .hero-section-content-area {
    padding-top: 20px;
  }

  .hero-section-content-area .main-title br {
    display: none;
  }

  .hero-section-content-area .main-title {
    font-size: 24px;
  }

  .main-title {
    font-size: 24px;
  }

  .sub-title {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .subTitle {
    font-size: 18px;
  }

  .text-paragraph,
  .check-icon-list li,
  .content-page p,
  .user-detail-content-area p,
  .faqWrapper .accordion-body,
  .faqWrapper .accordion-body p {
    font-size: 15px;
  }

  .faqWrapper .accordion-button {
    font-size: 16px;
  }

  .form-card {
    padding: 24px;
  }

  .form-title {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .form-text {
    font-size: 15px;
    margin-bottom: 24px;
  }

  .inner-page-padding {
    margin-block: 40px;
  }

  .benefitsRow > div:not(:last-child) {
    margin-bottom: 20px;
  }

  .registration-section-content-area {
    justify-content: center;
    padding: 40px 24px;
  }

  .registration-section-content-area .main-title {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-link {
    margin: 30px 0;
  }

  .footer-link .nav-link {
    font-size: 15px;
  }

  .tab-card {
    margin: 30px auto 0;
  }

  .achievements-section .nav-link {
    padding: 10px 24px;
    font-size: 14px;
  }

  .achievements-section .nav-item {
    margin: 4px;
  }

  .lession-note {
    font-size: 16px;
  }

  .note-area {
    font-size: 15px;
  }

  .model-btn {
    padding: 30px 0;
  }

  .common-popup .main-button {
    padding-inline: 10px;
  }

  .hable-des {
    padding-inline: 0;
  }

  .habla-outer .text-block {
    padding-inline: 0;
    padding-top: 65px;
  }

  .habla-uere-img {
    height: 50px;
    width: 50px;
  }

  .percent-div .CircularProgressbar {
    height: 120px;
    width: 120px;
  }

  .score-area .title {
    font-size: 24px;
  }

  .score-area p {
    font-size: 16px;
  }

  .score-area {
    margin: 0 auto 20px;
  }

  .checkout-page-form {
    display: block;
  }

  .side-plan-detail {
    margin-bottom: 30px;
  }

  .side-plan-detail .price {
    font-size: 28px;
  }

  .side-plan-detail p {
    margin-block: 5px;
  }

  .side-plan-detail {
    max-width: 100%;
  }

  .welcom_popup.common-popup .main-button {
    padding: 12px 30px;
  }

  .grammaticalContent h2 {
    font-size: 18px;
  }

  .grammaticalContent p,
  .user-detail-area table td {
    font-size: 15px;
  }

  .faqPage .two-block-content-area p {
    margin-bottom: 36px;
  }

  .signup_formBg {
    padding: 40px 20px;
  }

  .signup_formBg .form-card {
    padding: 25px 20px;
  }
}

@media (max-width: 575.98px) {
  .user-name .user-img + span {
    display: none;
  }

  .achievements-section .nav-link {
    padding: 8px 16px;
  }

  .note-area br {
    display: none;
  }

  .faqPageTitle {
    margin-bottom: 20px;
  }

  .form-social-buttons button div {
    font-size: 14px;
  }
}

@media (max-width: 479.98px) {
  .player {
    width: 210px;
  }

  .play-bar {
    width: 150px;
  }

  .play-icon {
    width: 36px;
    height: 36px;
    margin-right: 12px;
  }

  .play-icon i {
    font-size: 14px;
  }

  .common-popup .main-button {
    width: 100%;
  }

  .cardDiscount {
    width: 65px;
    height: 65px;
    font-size: 12px;
    top: -30px;
    right: 10px;
    line-height: 1.1;
  }

  .cardDiscount span {
    font-size: 20px;
  }

  .plan-card-row > div:not(:last-child) .plan-card {
    margin-bottom: 36px;
  }
}

@media (max-width: 375px) {
  .signup_formBg {
    height: auto;
  }
}

/* Reaponsive Css end */
/* test */

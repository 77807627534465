@media (min-width: 1300px) {
  .plan_section.inner_plan_section ul {
    max-width: 80%;
  }
}
@media (max-width: 1599px) {
  .plan_section {
    background-position: right calc((100vw - 1500px) / 2) top -95px;
  }
}
@media (max-width: 1399px) {
  .plan_section {
    background-position: right calc((100vw - 1310px) / 2) top -95px;
  }
  .inner_price_card.price_card,
  .price_card {
    grid-template-columns: 80px 1fr;
    padding-right: 0;
  }
  .inner_price_card.price_card .price_bookmark_tag,
  .price_bookmark_tag {
    right: -35px;
  }
}
@media (max-width: 1199px) {
  .plan_section {
    background-image: none;
  }
  .mobile_menu {
    position: fixed;
    max-height: 100dvh;
    width: 450px;
    max-width: 85%;
    top: 0;
    right: -150vw;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
    height: 100dvh;
    overflow: auto;
  }
  .mobile_menu.open {
    right: 0;
  }
  .close_menu {
    right: 25px;
    top: 25px;
  }
  body.noScroll {
    overflow: hidden;
  }
  .hiw_title.contact_title::after,
  .price_title.hiw_title::after,
  br {
    display: none;
  }
  .registraton_link {
    font-size: 22px;
    line-height: 37px;
  }
  .text_image_item:not(:last-child) {
    margin-bottom: 30px;
  }
  .access_tag {
    padding-right: 20px;
  }
  .price_card_bg {
    grid-template-columns: auto auto auto;
    gap: 20px;
    padding: 35px 30px 30px 70px;
  }
  .price_discount h3 {
    font-size: 24px;
  }
  .price_card {
    grid-template-columns: 40px 1fr;
  }

  .inner_price_card.price_card .price_bookmark_tag,
  .price_bookmark_tag {
    right: 0;
  }
  .outer_app .section-padding {
    padding-block: 100px;
  }
  .footer-link {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 991px) {
  .footer-link {
    margin-top: 0;
  }
  .b_hero_section {
    padding-block: 210px 310px;
    background-size: 100%, 520px, cover;
  }
  .registration-section {
    top: -4px;
  }
  .hiw_title::after {
    display: none;
  }
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  .order-3 {
    order: 3;
  }
  .order-4 {
    order: 4;
  }
  .inner_price_card.price_card,
  .price_card {
    grid-template-columns: 100%;
  }
  .price_bookmark_tag {
    right: unset;
    transform: translateX(-50%);
    left: 50%;
  }
  .price_card_bg {
    grid-template-columns: 100%;
    gap: 40px;
    padding: 65px 30px 30px 30px;
    text-align: center;
  }
  .access_tag {
    margin-inline: auto;
  }
  .price_action button,
  .price_action a {
    min-height: 40px;
    padding: 0 30px;
    font-size: 18px;
    line-height: 52px;
  }
  .signup_formBg {
    min-height: calc(100dvh - 88px);
  }
  .grntImage {
    position: absolute;
    left: 50px;
    width: 180px;
    bottom: 100px;
  }
}
@media (max-width: 767px) {
  .arrow_title b::after {
    display: none;
  }
  .blueGradient_sec .main-title {
    font-size: 22px;
    line-height: 32px;
  }
  .quote_sec > span {
    height: 110px;
    width: 110px;
    padding: 25px;
  }
  .quote_sec {
    padding-left: 76px;
  }
  .blueGradient_sec .title-section {
    padding: 40px 25px 40px 70px;
    border-radius: 25px 0 0 25px;
  }
  .contact_main_form h3,
  .registraton_link {
    font-size: 22px;
    line-height: 37px;
  }
  .footer-link {
    margin: 0;
  }
  .b_hero_content h1 {
    font-size: 32px;
    line-height: 44px;
  }
  .b_CONSIGUE_section {
    background-position: bottom -140px center;
  }
  .functional_section .main-title::after,
  .b_CONSIGUE_section .main-title::after {
    display: none;
  }
  .main_faq_sec .accordion-item .accordion-button {
    font-size: 18px;
  }
  .main_faq_sec {
    padding-bottom: 80px;
  }
  .main_faq_sec .accordion-item .accordion-body {
    padding-top: 20px;
  }
  .main_faq_sec .accordion-item {
    padding: 20px;
  }
  .main_faq_sec .accordion-item:not(:last-child) {
    margin-bottom: 20px;
  }
  .contact_form {
    grid-template-columns: 100%;
    margin-top: 20px;
  }
  .contact-page .main-title + .text-paragraph {
    margin-bottom: 0;
  }
  .b_CONSIGUE_section .main-title {
    padding-left: 0;
  }
  .inner_plan_section .hiw_title {
    margin-bottom: 40px;
  }
  .inner_price_card .price_card_bg {
    padding: 130px 30px 60px;
  }
  .grntImage {
    left: 50%;
    transform: translateX(-50%);
    bottom: unset;
    top: -80px;
  }
}

@media (max-width: 575px) {
  .benefits_item {
    grid-template-columns: 100%;
  }
  .b_over_img {
    border: 3px solid #fff;
    border-radius: 30px;
  }
  .paso_title,
  .benefit_text h4,
  .function_item h4,
  .text_image_list h3,
  .learnCard .learnCardTitle {
    font-size: 22px;
  }
  .learnCardBody p {
    padding: 0;
  }
  .learnCardBody {
    padding: 20px;
    border-radius: 30px;
  }
  .learnCard {
    border-radius: 30px;
  }
  .registration-section {
    top: -24px;
  }
  .registraton_link {
    font-size: 18px;
    line-height: 24px;
  }
  .b_menu_list a.b_btn {
    font-size: 16px;
    min-height: 40px;
    min-width: auto;
    gap: 8px;
  }
  .b_menu_list a.b_btn img {
    width: 18px;
  }
  .text_image_list p,
  .benefits_item p {
    text-align: left;
  }
  .contact_form_part label,
  .main_faq_sec .accordion-item .accordion-body,
  .plan_section ul li,
  .hiw_title.price_title p,
  .benefit_text h4 + p,
  .text_image_list p,
  .function_item p,
  .b_brain_img_sec .text-paragraph,
  .b_CONSIGUE_section .text-paragraph,
  .garantia_Sec .text-paragraph,
  .benefits_item p {
    font-size: 16px;
  }
  .garantia_Sec {
    padding-bottom: 80px;
  }
  .titleSection h1 {
    font-size: 28px;
  }
  .plan_section ul {
    grid-template-columns: 100%;
  }
  .price_card_bg {
    gap: 30px;
  }
}
